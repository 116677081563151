<template>
    <el-scrollbar class="seller-scrollbar">
        <el-card class="seller-card">
            <el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('seller.breadcrumb.seller')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'marketingtools' }">{{$t('seller.breadcrumb.marketPlatform')}}
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'singletreasureList' }">{{$t('seller.breadcrumb.singletreasure')}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="page-section">
                <div class="section-steps">
                    <el-steps :active="stepActive" align-center>
						<el-step v-for="item in $t('seller.singletreasure.stepsOption')" :key="item" :title="item"></el-step>
                    </el-steps>
                </div>
                <div class="section-title"><span>{{ $t('seller.coupon.paneTitle2')}}</span></div>
				<el-form ref="postForm" :model="postForm" :rules="postRule" size="mini" label-width="110px">
					<el-form-item :label="$t('seller.screen.campaignTag')+'：'" prop="single_treasure_label_id">
						<div style="margin-bottom: 10px">
							<el-radio v-model="postForm.label_type" :label="1" :disabled="['view'].includes(actionType)" @change="handleChangeLabel">日常活动</el-radio>
							<el-cascader
								v-if="postForm.label_type === 1"
								v-model="postForm.single_treasure_label_id"
								:placeholder="$t('seller.placeholder.campaignTag')"
								:options="labelOptions1"
								:props="{emitPath: false,value:'single_treasure_label_id',label:'label_name',children:'children'}"
								:show-all-levels="false"
								filterable
								clearable
								:disabled="['view'].includes(actionType)">
							</el-cascader>
						</div>
						<div>
							<el-radio v-model="postForm.label_type" :label="2" :disabled="['view'].includes(actionType)" @change="handleChangeLabel">官方活动</el-radio>
							<el-cascader
								v-if="postForm.label_type === 2"
								v-model="postForm.single_treasure_label_id"
								:placeholder="$t('seller.placeholder.campaignTag')"
								:options="labelOptions2"
								:props="{emitPath: false,value:'single_treasure_label_id',label:'label_name',children:'children'}"
								:show-all-levels="false"
								filterable
								clearable
								:disabled="['view'].includes(actionType)">
							</el-cascader>
						</div>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.campaignName')+'：'" prop="name">
						<div>
							<el-input v-model="postForm.name" :placeholder="$t('seller.placeholder.maxLength20')" :disabled="['view'].includes(actionType)" style="width: 322px;"></el-input>
						</div>
						<div class="tips">{{ $t('seller.singletreasure.activityNameTips')}}</div>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.startTime')+'：'" prop="start_time">
						<el-date-picker v-model="postForm.start_time" type="datetime" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')" value-format="timestamp"
							:end-placeholder="$t('seller.placeholder.endDate')" :picker-options="pickerStartOptions" :disabled="['view'].includes(actionType)">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.endTime')+'：'" prop="end_time">
						<el-date-picker v-model="postForm.end_time" type="datetime" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')" value-format="timestamp"
							:end-placeholder="$t('seller.placeholder.endDate')" :disabled="['view'].includes(actionType)">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.couponLevel')+'：'">
						<el-radio-group v-model="postForm.dis_level" :disabled="['edit','view'].includes(actionType)">
							<el-radio v-for="item,key in $t('seller.filters.discountLevel')" :key="key" :label="parseInt(key)">{{ item }}</el-radio>
						</el-radio-group>
						<div class="tips"><i class="el-icon-warning-outline"></i> {{ $t('seller.singletreasure.discountLevelTips') }}</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.preferentialWay')+'：'">
						<el-radio-group v-model="postForm.dis_type" :disabled="['edit','view'].includes(actionType)">
							<el-radio v-for="item,key in $t('seller.filters.discountType')" :key="key" :label="parseInt(key)">{{item}}</el-radio>
						</el-radio-group>
						<div class="tips"><i class="el-icon-warning-outline"></i> {{ $t('seller.singletreasure.discountTypeTips') }}</div>
					</el-form-item>
					<el-form-item :label="$t('seller.formItem.activityFree')+'：'">
						<el-checkbox v-model="postForm.is_free_ship" :label="$t('seller.unit.freeShipping')" :true-label="1" :false-label="0" :disabled="['view'].includes(actionType)" @change="handleChangeShip"></el-checkbox>
						<span class="region-text" v-show="postForm.is_free_ship === 1">
							<el-button type="text" @click="handleShowRegion" :disabled="['view'].includes(actionType)">{{ $t('seller.actions.nonPostalArea')}}</el-button>
							<span v-html="regionText"></span>
						</span>
					</el-form-item>
					<el-form-item label-width="0" v-if="['add','edit'].includes(actionType)">
						<el-button type="primary" @click="handleSubmit">{{ $t('seller.actions.saveAndContinue') }}</el-button>
						<el-button @click="$router.push({ name: 'singletreasureList'})">{{ $t('seller.actions.cancel') }}</el-button>
					</el-form-item>
					<el-form-item label-width="0" v-else>
						<el-button @click="$router.push({ name: 'singletreasureList' })">{{ $t('seller.actions.back') }}</el-button>
					</el-form-item>
				</el-form>
				<el-dialog
					:title="$t('seller.actions.nonPostalArea')"
					:visible.sync="dialogVisibleRegion"
					width="600px" custom-class="seller-dialog">
					<div class="dialog-region">
						<div class="item" v-for="item in regionList" :key="item.area_region">
							<div class="item-label">
								<el-checkbox v-model="checkArea" :label="item.area_region" @change="handleCheckArea($event,item.area_region)"></el-checkbox>
							</div>
							<div class="item-block">
								<el-checkbox-group v-model="checkRegion" @change="handleCheckRegion($event,item.area_region)">
									<el-checkbox v-for="child in item.list" :key="child.region_id" :label="child">{{ child.region_name }}</el-checkbox>
								</el-checkbox-group>
							</div>
						</div>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button @click="dialogVisibleRegion = false">{{ $t('seller.actions.cancel') }}</el-button>
						<el-button type="primary" @click="handleSubmitRegion">{{ $t('seller.actions.confirm') }}</el-button>
					</div>
				</el-dialog>
            </div>
        </el-card>
    </el-scrollbar>
</template>

<script>

import { fetchTreasuresInfo, fetchTreasuresTags, fetchTreasuresRegions, createTreasures, updateTreasures } from '@/api/seller/market'
export default {
	computed: {
		regionText() {
			let region = []
			if(this.postForm.region && this.postForm.region.length > 0) {
				this.postForm.region.forEach(item => {
					region.push(item.region_name)	
				})
			}
			return region.join('、')
		}
	},
	data() {
		var that = this
		var validateLabel = (rule, value, callback) => {
			if(value > 0) {
				callback()
			} else {
				callback(new Error(this.$t('seller.errorMsg.campaignTag')))
			}
		}
		var validateStartTime = (rule, value, callback) => {
			if(value === '') {
				callback(new Error(this.$t('seller.errorMsg.campaignStartTime')))
			} else {
				callback()
			}
		}
		var validateEndTime = (rule, value, callback) => {
			if(value === '') {
				callback(new Error(this.$t('seller.errorMsg.campaignEndTime')))
			} else if(value < that.postForm.start_time) {
				callback(new Error(this.$t('seller.errorMsg.campaignTime')))
			} else {
				callback()
			}
		}
		return {
            actionType: 'add',
			stepActive: 0,
			labelOptions1: [],
			labelOptions2: [],
			pickerStartOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 86400000
				}
			},
			dialogVisibleRegion: false,
			regionList: [],
			checkArea: [],
			checkRegion: [],
			postForm: {
				label_type: 1,
				single_treasure_label_id: 0,
				name: '',
				start_time: Date.now(),
				end_time: '',
				dis_level: 1,
				dis_type: 1,
				is_free_ship: 0,
				region: []
			},
			postRule: {
				single_treasure_label_id: [
					{ required: true, validator: validateLabel, trigger: 'change' }
				],
				name: [
					{ required: true, message: this.$t('seller.validator.campaignNameMax20'), trigger: 'change' }
				],
				start_time: [
					{ required: true, validator: validateStartTime, trigger: 'change' }
				],
				end_time: [
					{ required: true, validator: validateEndTime, trigger: 'change' }
				]
			}
		}
	},
	created () {
		this.getData()
	},
	methods: {
		getData() {
			fetchTreasuresTags().then(response => {
				const { data } = response
				this.labelOptions1 = data.label_1
				this.labelOptions2 = data.label_2
			})
			fetchTreasuresRegions().then(response => {
				const { data } = response
				this.regionList = data.list
			})
			if(this.$route.params.id) {
				fetchTreasuresInfo({ single_treasure_id: this.$route.params.id }).then(response => {
					const { data } = response
					this.postForm = data
					this.postForm.start_time = this.postForm.start_time * 1000
					this.postForm.end_time = this.postForm.end_time * 1000
                    if(this.postForm.is_delete === 1 || this.postForm.status === 1 || this.postForm.end_time < Date.now()) {
                        this.actionType = 'view'
                    } else {
                        this.actionType = 'edit'
                    }
				})
			}
		},
		handleChangeLabel(val) {
			this.postForm.single_treasure_label_id = 0
		},
		handleChangeShip(val) {
			if(val === 1) {
				this.$confirm(this.$t('seller.confirm.activityFree'),'',{
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning'
				}).then(() => {
				}).catch(() => {
					this.postForm.is_free_ship = 0
				})
			}
		},
		handleShowRegion() {
			this.dialogVisibleRegion = true
		},
		handleCheckArea(bool,val) {
			var checkArr = []
			this.checkRegion.forEach(item => {
				if(bool && !this.checkArea.includes(item.area_region)) {
					checkArr.push(item)
				} else if (!bool && val!==item.area_region) {
					checkArr.push(item)
				}
			})
			this.regionList.forEach(item => {
				if(this.checkArea.includes(item.area_region)) {
					checkArr = checkArr.concat(item.list)
				}
			})
			this.checkRegion = checkArr
		},
		handleCheckRegion(val,area) {
			var sum = 0
			this.checkRegion.forEach(item => {
				if(item.area_region === area) {
					sum ++
				}
			})
			this.regionList.forEach(item => {
				if(item.area_region === area) {
					if(item.list.length === sum && !this.checkArea.includes(item.area_region)) {
						this.checkArea.push(area)
					} else if (item.list.length !== sum && this.checkArea.includes(item.area_region)) {
						this.checkArea.splice(this.checkArea.indexOf(area),1)
					}
				}
			})
		},
		handleSubmitRegion() {
			this.postForm.region = Object.assign([], this.checkRegion)
			this.dialogVisibleRegion = false
		},
		handleSubmit() {
			this.$refs.postForm.validate((valid) => {
				if(valid) {
					const params = {
						...this.postForm
					}
					params.start_time = parseInt(params.start_time / 1000)
					params.end_time = parseInt(params.end_time / 1000)
					params.region = JSON.stringify(params.region)
					if(params.single_treasure_id > 0) {
						updateTreasures(params).then(() => {
							this.$confirm(this.$t('seller.confirm.activityFree'),this.$t('seller.successMsg.save'),{
								confirmButtonText: this.$t('seller.actions.confirm'),
								cancelButtonText: this.$t('seller.actions.cancel'),
								type: 'warning'
							}).then(() => {
								this.$router.push({name: 'singletreasureItemDetail',params: {id: params.single_treasure_id }})
							}).catch(() => {
								this.$router.push({name: 'singletreasureList'})
							})
						})
					} else {
						createTreasures(params).then(response => {
							const { data } = response
							this.$router.push({name: 'singletreasureAddItem',params: {id: data.single_treasure_id }})
						})
					}
				} else {
					return false
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>


.page-section {
	.section-steps {
		padding: 20px 0;
	}

	.section-title {
		padding: 10px 20px;
		line-height: 20px;
		font-size: 14px;
		margin-left: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #e6e6e6;

		.action {
			float: right;
			white-space: nowrap;

			a {
				font-size: 12px;
				margin-left: 10px;
			}
		}
	}
	.tips {
		color: #666666;
		.el-icon-warning-outline {
			font-size: 16px;
			color: #ff7a45;
			margin-right: 4px;
		}
	}
	.region-text {
		color: #666666;
		margin-left: 10px;
		a {
			color: $--seller-primary;
		}
		span {
			margin-left: 10px;
		}
	}
}
.dialog-region {
	.item {
		display: flex;
		.item-label {
			width: 70px;
			flex-shrink: 0;
			padding: 8px 5px;
		}
		.item-block {
			flex: 1;
			width: 0;
			padding: 8px 5px;
		}
	}
}
</style>